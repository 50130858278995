import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import {
  LoadingScreen,
  ResourceSection,
  Table
} from '@mesa-labs/mesa-ui';

import { useDispatch, useSelector } from '../../../redux/hooks';
import { useGetVendorQuery, useGetVendorUserAccountActivityMetricsQuery } from '../../../redux/api/vendors';
import { ColumnProp } from '@mesa-labs/mesa-ui/dist/components/Table';
import { DateTime } from 'luxon';
import { useTimezoneSelection } from '../../../utils';

const VendorActivityContainer = styled.div``;

const VendorActivityColumns: ColumnProp[] = [
  {
    key: 'lastAuthSignIn',
    label: 'Last Auth Sign In',
  },
  {
    key: 'lastAuthSignOut',
    label: 'Last Auth Sign Out',
  },
  {
    key: 'lastAuthRefresh',
    label: 'Last Auth Refresh',
  },
  {
    key: 'lastResourceAccess',
    label: 'Last Resource Access',
  },
  {
    key: 'numUniqueDaysResourceAccessLast90',
    label: 'Num Unique Days Resource Access Last 90',
  },
];

function VendorActivityPage(): React.ReactElement | null {
  const { id: vendorId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: vendor } = useGetVendorQuery({ id: vendorId! }, { skip: !vendorId });

  const vendorHasBeenActivated = useMemo(() => !!vendor?.activatedAt, [vendor]);

  const {
    data: vendorActivity,
    isLoading: vendorActivityLoading,
  } = useGetVendorUserAccountActivityMetricsQuery({ vendorId: vendorId! }, { skip: !vendorId || !vendorHasBeenActivated });

  if (!vendor || vendorActivityLoading) {
    return <LoadingScreen />;
  }

  const vendorActivityTableRows = [{
    lastAuthSignIn: vendorActivity?.lastAuthSignIn ? useTimezoneSelection(DateTime.fromISO(vendorActivity?.lastAuthSignIn || '')).toFormat("MMM d, yyyy 'at' h:mma") : 'Unknown',
    lastAuthSignOut: vendorActivity?.lastAuthSignOut ? useTimezoneSelection(DateTime.fromISO(vendorActivity?.lastAuthSignOut || '')).toFormat("MMM d, yyyy 'at' h:mma") : 'Unknown',
    lastAuthRefresh: vendorActivity?.lastAuthRefresh ? useTimezoneSelection(DateTime.fromISO(vendorActivity?.lastAuthRefresh || '')).toFormat("MMM d, yyyy 'at' h:mma") : 'Unknown',
    lastResourceAccess: vendorActivity?.lastResourceAccess ? useTimezoneSelection(DateTime.fromISO(vendorActivity?.lastResourceAccess || '')).toFormat("MMM d, yyyy 'at' h:mma") : 'Unknown',
    numUniqueDaysResourceAccessLast90: vendorActivity?.numUniqueDaysResourceAccessLast90 ? vendorActivity?.numUniqueDaysResourceAccessLast90 : 'Unknown',
  }];

  return (
    <VendorActivityContainer>
      <>
        <ResourceSection
          title="Vendor Activity"
        >
          <Table
            columns={VendorActivityColumns}
            rows={vendorActivityTableRows}
          />
        </ResourceSection>
      </>
    </VendorActivityContainer>
  );
}

export default VendorActivityPage;
